<template>
  <v-toolbar class="mb-2">
    <v-btn
      v-if="btn"
      icon
      @click="$router.go(-1)"
    >
      <v-icon>fas fa-chevron-left</v-icon>
    </v-btn>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
  </v-toolbar>
</template>

<script>
  export default {
    name: 'HeaderPage',
    props: {
      title: {
        type: String,
        default: '',
      },
      btn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
